import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "QuickStart MongoDB With Docker",
  "author": "tzookb",
  "type": "post",
  "date": "2019-07-21T05:33:21.000Z",
  "url": "/2019/07/quickstart-mongodb-with-docker/",
  "medium_post": ["O:11:\"Medium_Post\":11:{s:16:\"author_image_url\";N;s:10:\"author_url\";N;s:11:\"byline_name\";N;s:12:\"byline_email\";N;s:10:\"cross_link\";N;s:2:\"id\";N;s:21:\"follower_notification\";N;s:7:\"license\";N;s:14:\"publication_id\";N;s:6:\"status\";N;s:3:\"url\";N;}"],
  "categories": ["How To"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` Im gonna share how to get mongoDB up and running on your machine super quick with docker. First if you want to learn a bit more about mongo, you can go to their site and start devouring all their FREE courses `}<a rel="noreferrer noopener" aria-label="here (opens in a new tab)" href="https://university.mongodb.com/courses/catalog" target="_blank">{`here`}</a>{`. I was using mongo with docker for quite some time, but it was with custom dockerfiles and docker-compose. I had one super quick feature I wanted to show a friend on mongo. But who has the energy to create a dockerfile or docker-compose file just to show something quick. There are few options I got (I`}{`’`}{`m sure there are more) : `}</p>
    <h4>{`running on the spot`}</h4>
    <pre><code parentName="pre" {...{}}>{`docker run --rm -v $(PWD):/var/data -p 27017:27017 -it mongo:3.4 bash
`}</code></pre>
    <p>{`The above will start a mongo container and will get you inside to the container with bash access. Docker is `}</p>
    <p><strong parentName="p">{`not`}</strong>{` running yet. But now as you are inside the container you can easily interact with mongo. `}</p>
    <pre><code parentName="pre" {...{}}>{`mongod --fork --logpath /var/log/mongod.log
`}</code></pre>
    <p>{`This will start mongo and go to the background so you could continue interacting with the container. `}</p>
    <pre><code parentName="pre" {...{}}>{`mongo
`}</code></pre>
    <p>{`now you are inside the mongo shell and you can interact with databases, collections etc. To quickly create a new db and collection: `}</p>
    <pre><code parentName="pre" {...{}}>{`use sample_db 
# now you have a new DB name "sample_db"

db.books.save({title: "some book"})
# now you have a new collection named "books" with one doc
`}</code></pre>
    <p>{`** when you exit the container, it will get removed. `}</p>
    <h4>{`Running mongo container on the background of your computer`}</h4>
    <pre><code parentName="pre" {...{}}>{`docker run --rm -d -v $(PWD):/var/data -p 27017:27017 -it --name tzmong mongo:3.4
`}</code></pre>
    <p>{`This command will start a new docker container with mongo already running. The container name will be `}{`“`}{`tzmong`}{`”`}{`. Now you can easily access it with: `}</p>
    <pre><code parentName="pre" {...{}}>{`docker exec -it tzmong mongo
`}</code></pre>
    <p>{`And now you are inside mongo shell again as before and you create dbs, collections etc. Now if you exit the container, the container will still be alive and running. If you would like to stop it, run: `}</p>
    <pre><code parentName="pre" {...{}}>{`docker stop tzmong
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      